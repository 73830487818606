<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>This is detail of doctor</h1>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'doctor-detail',
  data() {
    return {
    };
  },
  
  created() {
    this.getDoctorsList();
  }
};
</script>