<template>
  <div>
    <doctor-detail></doctor-detail>
  </div>
</template>

<script>

import doctorDetail from '../../components/doctors/doctorDetail.vue'

export default {
  components: {
      doctorDetail
  },
  data:()=>({
  })
}
</script>